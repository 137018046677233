.HeaderLine {
  width: 80%;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
  height: 1px;
  margin-top: 10px;
  background-color: transparent;
  background-image: radial-gradient(circle, #579250 0, transparent 100%);
}

.BalanceSection {
  display: flex;
  align-items: center;
  border-radius: 13px;
  width: 90%;
  margin: 5px;
}

.BalancePart {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contractAddress {
  box-sizing: border-box;
  width: 12%;
  height: 40px;
}

.DateText {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  padding-right: 20px;
}

.ContractContainer {
  color: white;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 30px;
  border: 4px solid;
  border-color: #3b273c;
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    radial-gradient(circle farthest-corner at 66% 16%,
      rgba(2, 245, 255, 1) 33%,
      rgba(255, 94, 247, 1) 79%);
  background-size: auto;
  background-position: 0% 0%;
  background-repeat: repeat;
}

.loadingBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.DepositBoxHeader {
  margin-top: -20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.MainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 10px;
  gap: 10px;
  background: #151515;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 5px;
  left: calc(50% - 359px / 2 - 189.5px);
  top: 361px;
}

.MainContainerShitlordPump {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 10px;
  gap: 10px;
  background: #27462b;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 5px;
  left: calc(50% - 359px / 2 - 189.5px);
  top: 361px;
  border: solid #f3cc2f 1px;
}

.PresaleProgressContainer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.85));
  padding: 40px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Text1 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #f8ffe8;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.Text2 {
  margin-bottom: 10px;
  margin-top: 10px;
  color: white;
}

.InputSection_LP {
  width: 90%;
}

.MaxButton {
  cursor: pointer;
  margin-top: 0;
}

.CopyButton {
  background: transparent;
  border-radius: 5px;
  border: 0px solid transparent;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 0;
  /* margin: 0 auto; */
  height: 38px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.CopyButton:hover {
  color: white;
  cursor: pointer;
}

.CreateButton {
  background: #f2cb2f;
  border-radius: 25px;
  border: 1px solid black;
  box-shadow: rgb(0, 0, 0) 1px 1px 0px 0px;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 0;
  width: 90%;
  margin: 0 auto;
  height: 48px;
  color: black;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
}

.CreateButton:hover {
  color: black;
  cursor: pointer;
}

.CreateButton:active {
  color: black;
  cursor: pointer;
}

.CreateButton:disabled {
  /*background: #c4b7c533;*/
  color: #434947;
  cursor: unset;
}

.LockBox {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.MainDashboard {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  margin-bottom: 10px;
}

.ConnectWalletBox {
  text-align: center;
  height: -webkit-fill-available;
  margin: auto;
  /* margin-bottom: 200px; */
  margin-top: 10%;
  margin-bottom: 20%;
}

.ConnectWalletBoxButton {
  display: flex;
  justify-content: space-evenly;
}

.SpinnerBox {
  display: flex;
  justify-content: space-evenly;
}

.ConnectWalletAgain {
  width: 100%;
  text-align: -webkit-center;
  height: 30%;
  margin-bottom: 30%;
}

.ButtonContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ConnectButton {
  width: 25%;
  outline: none;
  border: 0px solid transparent;
  border-image-slice: 1;
  align-items: center;
  font-size: 17px;
  margin-left: -60px;
  margin-bottom: -15px;
  font-weight: 500;
  color: white;
  transition: 0.1s ease-in-out;
  height: 90%;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
    linear-gradient(90deg,
      rgb(174, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(210, 71, 203) 73.6%,
      rgb(215, 103, 237) 102.14%) !important;
  backdrop-filter: blur(38.5864px) !important;
  padding: 10px 30px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  /* text-shadow: 1px 1px 4px black; */
  display: block;
  font-weight: 600;
  transform: scale(1, 1);
}

.navConnectButton {
  width: 184px;
  outline: none;
  font-family: 'Dela Gothic One';
  border-radius: 100px;
  border: 0px solid transparent;
  border-image-slice: 1;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: white;
  transition: 0.1s ease-in-out;
  height: 50px;
  cursor: pointer;
  background: #53a553;
  padding: 10px 10px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  display: block;
  font-weight: 600;
  transform: scale(1, 1);
}

.ConnectButton:active {
  background-color: transparent;
  color: #26c5eb;
  transform: scale(1);
  cursor: pointer;
}

.ConnectButton:hover {
  background-position: right center;
  background-color: transparent;
  border: 0px solid #04a58a54;
  color: white;
  text-decoration: none;
}

.ConnectButton:disabled {
  background-color: #a6a6a6;
  box-shadow: none;
  cursor: no-drop;
}

.gradient-text {
  background: linear-gradient(90deg, rgb(109, 216, 212) 10%, rgb(2 206 107) 60%) text transparent;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
}

.GlobalContainer {
  background: linear-gradient(180deg, #3b7642 0%, #34693d 100%);
  width: auto;
  position: relative;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  padding-top: 120px;
  overflow-x: hidden;
  overflow-y: hidden !important;
  min-height: 100vh;
}

.inputPanel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.inputPanel>p {
  text-wrap: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}

.InputSection {
  width: 45%;
}

.InputSection_Description {
  width: 100%;
}

.inputPanelHeader {
  margin-top: -6px;
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
}

.InputBox {
  width: 90%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.InputContainer {
  box-sizing: border-box;
  background: #151515;
  border: solid #f3cc2f 1px;
  border-radius: 5px;
  flex: none;
  flex-grow: 0;
  /* padding-left: 10px; */
}

.InputContainer input::placeholder {
  color: #aaa;
}

.LpInputBox {
  width: 55%;
}

.inputPanel_lp {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

.DashboardContainer {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  /* height: 50px; */
}

.LpBalance {
  display: flex;
  width: 100%;
  margin-bottom: -5px;
  justify-content: space-between;
}

.sub {
  position: absolute;
  margin-top: 20px;
  margin-left: 3px;
  width: auto;
}

.ContractContentTextTitle {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  font-size: 36px;
  color: #f8ffe8;
}

.accountDetail {
  display: flex;
}

.accountData {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  flex-direction: column;
}

.withdrawContent {
  width: -webkit-fill-available;
  display: flex;
}

.LoadingBox {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FirstNote {
  color: #1a449d;
  font-size: 25px;
}

.HeaderText {
  font-size: 20px;
  text-align: initial;
}

.HeaderContent {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  color: rgb(196, 196, 196);
  font-size: 14px;
  flex-direction: row;
}

.HeaderContent>p {
  margin: 0px;
}

.HeaderContent2 {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  color: white;
  font-size: 20px;
  flex-direction: row;
}

.HeaderContent2>p {
  margin: 5px 0px 5px 0px;
}

.HeaderContent3 {
  width: -webkit-fill-available;
  height: 15px;
  border: 1px solid transparent;
  border-radius: 12px !important;
  background: white;
}

.HeaderContent4 {
  background: linear-gradient(90deg,
      rgb(174, 134, 37) -3.12%,
      rgb(247, 239, 138) 29.71%,
      rgb(210, 172, 71) 73.6%,
      rgb(237, 201, 103) 102.14%) !important;
  height: 15px;
  border-radius: 12px;
}

.HeaderContent5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgb(196, 196, 196);
}

.HeaderContent6 {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
}

.HeaderContent7 {
  font-size: 14px;
}

.stageBox {
  border-color: rgb(4, 165, 138);
  border-radius: 8px;
  border-width: 1px;
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 10px;
}

.starting>p {
  margin: 0px;
  font-weight: 600;
}

.unstarted>p {
  margin: 0px;
  font-weight: 500;
}

.starting {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(0deg,
      rgb(163, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(199, 71, 210) 73.6%,
      rgb(226, 103, 237) 102.14%) !important;
  backdrop-filter: blur(38.5864px) !important;
  color: white;
  border: 1px solid white;
  text-shadow: 0px 0px 4px black;
}

.unstarted {
  background: transparent;
  color: white;
  border: 1px solid white;
}

.one_how {
  position: relative;
  color: #fff;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.one_how::before {
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 25%;
  background: white;
  left: 0;
}

.one_how::after {
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 25%;
  background: white;
  right: 0;
}

.tokensButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.tokenImage {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.tokenHeader {
  margin-left: 30px;
  height: 50px;
  padding: 5px;
  padding-top: 5px;
}

.tokenHeaderNoText {
  height: 75%;
}

.tokenButtons {
  display: flex;
  padding: 3px 15px 3px 15px;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
  color: white;
  border: 0.5px solid white;
  cursor: pointer;
  width: 30%;
  height: 50px;
  justify-content: center;
  font-size: 18px;
}

.HeaderList {
  text-align: initial;
}

.selected {
  border: 0.5px solid white;
  background: rgb(50, 35, 62);
}

.HeaderOfferText {
  text-align: initial;
  font-size: 17px;
}

.HeaderList>li {
  padding-bottom: 5px;
}

.LpDataContainer {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: white; */
  border-radius: 10px;
  border: 1px solid rgba(9, 62, 82, 0.2);
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border: 0.1px solid #555555;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  margin-bottom: 10px;
}

.ButtonContainer {
  display: flex;
  width: 100%;
}

.connectButtonBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ButtonBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.navConnectButtonBox {
  display: flex;
  justify-content: center;
}

.ChainGroupButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.topRightMoonLogo {
  z-index: -1;
  position: absolute;
  width: 300px;
  right: 3000;
  height: 400px;
  top: 50px;
}

.headerMargin {
  margin-bottom: 5px;
}

.h1 {
  font-size: 30px;
  /* text-shadow: 1px 1px 4px black;
  text-shadow: 0px 0px 1px black, 0px 0px 2px black, 0px 0px 4px black; */
}

::placeholder {
  color: rgb(71, 69, 69);
}

.tokenLists {
  font-size: 14px;
  color: white;
}

.chatLists {
  font-size: 14px;
  color: white;
  margin: 5px;
}

.chatContent {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.Metamask {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.TokenAdder {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  padding: 9px 0;
  width: 100%;
}

.tooltiptext {
  color: white;
  background-color: transparent;
}

.Balance-header-container {
  margin-left: 30px;
}

.trade-box {
  max-width: 800px;
  min-width: 280px;

  flex: none;
  order: 2;
  flex-grow: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
  margin-top: 20px;
  display: flex;
}

.Trade-token-name {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #f8ffe8;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 8px;
}

.balance-token-logo {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.balanceContent {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
}

.tooltip {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 1600px) {
  .withdrawContent {
    flex-direction: column;
  }

  .ContractContainer {
    max-width: 60vw;
  }

  .launches-switch-all {
    width: calc(100% - 344px);
  }
}

@media screen and (max-width: 1050px) {
  .withdrawContent {
    flex-direction: column;
  }

  .ContractContainer {
    width: 80%;
    max-width: 100vw;
  }
}

@media screen and (max-width: 640px) {
  .GlobalContainer {
    padding: 0px;
  }

  .footer {
    flex-direction: column;
  }

  .BalanceSection {
    width: auto;
  }

  .tokenButtonText {
    font-size: 16px;
  }

  /* .navWallet {
    display: none;
  } */

  .navConnectButton {
    width: 130px;
    padding: 8px;
    font-size: 12px;
  }
}

@media screen and (max-width: 420px) {
  .tokenButtonText {
    font-size: 12px;
  }

  .h1 {
    font-size: 24px;
  }

  .ContractContentTextTitle {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }

  .ChainGroupButton {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: min-content;
    justify-content: space-around;
  }
}

@media screen and (max-width: 480px) {
  .navLink {
    display: none;
  }

  .tokenHeader {
    height: 30px;
    margin-right: 3px;
  }

  .tokenLists {
    font-size: 11px;
  }
}

@media screen and (min-width: 480px) {
  .tokenHeaderNoText {
    display: none;
  }
}

@media screen and (max-width: 370px) {
  .tokenButtonText {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .tokensButton {
    width: 100%;
  }

  .ConnectButton {
    width: 140px;
    margin-bottom: 10px;
  }

  .HeaderContent6 {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }

  .InputSection {
    width: 100%;
  }

  .contractAddress {
    width: auto;
  }

  .inputPanel {
    flex-direction: row;
    width: 100%;
  }

  .inputPanelHeader {
    flex-direction: row;
    width: 100%;
  }

  .ContractContainer {
    width: 100%;
  }

  .DashboardContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.FAQ {
  max-width: 800px;
  width: 800px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.ResponsiveFlexLayout {
  display: flex;
  justify-content: center;
  width: 1440px;
  max-width: 1440px;
}

.RevokeTokenSelect {
  width: 570px;
}

.claim-eth-token-logo {
  visibility: visible;
  height: 22px;
  display: flex;
  width: 22px;
  align-items: center;
  position: absolute;
  position: absolute;
  left: 255px;
  color: blue;
  cursor: pointer;
}

.LpTokenSelect {
  width: 285px;
  margin-right: -55px;
}

.BurnLpTokenSelect {
  width: -webkit-fill-available;
}

.BuyMax {
  position: absolute;
  top: -14px;
  left: 217px;
  color: #b04851;
  cursor: pointer;
}

.holderBox {
  display: flex;
  width: auto;
  justify-content: space-between;
}

.holderBox>p {
  color: white;
  margin: 0px;
}

.tradeBox>div {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tradeBox {
  display: flex;
  width: auto;
  justify-content: space-between;
}

.chatBox>div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.chatBox {
  display: flex;
  width: auto;
  justify-content: space-between;
  flex-direction: column;
  background: #1a2d1d;
  border-radius: 25px;
}

.tradeBox>p {
  color: rgb(177, 228, 135);
  width: 20%;
  display: flex;
  justify-content: center;
}

.SendButton {
  background: #f3cc2f;
  border-radius: 5px;
  border: 0px solid transparent;
  /* flex: none; */
  /* order: 1; */
  /* flex-grow: 0; */
  /* z-index: 0; */
  width: 100%;
  margin: 0 auto;
  height: 42px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.holderContent {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
}

.chartTab_outer {
  width: 100%;
  height: 400px;
  z-index: 1;
}

.chartTab_container {
  width: 100%;
  height: 100%;
  /* padding: 1%; */

  background-color: white;
}

.chart {
  width: -webkit-fill-available;
  height: 400px;
}

.holderContent>svg {
  color: white;
  width: 15%;
}

.createShitlordFlexLayout {
  width: 1280px;
  max-width: 1280px;
  margin: auto;
}

.EmptyLaunchpad {
  margin-bottom: 25%;
}

@media (max-width: 1500px) {
  .ResponsiveFlexLayout {
    display: flex;
    justify-content: center;
    width: 1280px;
    max-width: 1280px;
  }

  .createShitlordFlexLayout {
    width: 1200px;
    max-width: 1200px;
  }

  .LpTokenSelect {
    width: 250px;
  }

  .RevokeTokenSelect {
    width: 500px;
  }
}

@media (max-width: 1400px) {
  .createShitlordFlexLayout {
    width: 860px;
    max-width: 860px;
  }
}

@media (max-width: 1340px) {
  .ResponsiveFlexLayout {
    display: flex;
    justify-content: center;
    width: 1024px;
    max-width: 1024px;
  }

  .LpTokenSelect {
    width: 193px;
  }

  .RevokeTokenSelect {
    width: 386px;
  }
}

@media (max-width: 1280px) {
  .ResponsiveFlexLayout {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 512px;
    max-width: 512px;
  }

  .BuyMax {
    top: -14px;
    left: 350px;
  }

  .claim-eth-token-logo {
    left: 389px;
  }

  .LpTokenSelect {
    width: 198px;
  }

  .RevokeTokenSelect {
    width: 396px;
  }

  .ExchangeForm {
    padding-top: 0px;
  }

  .ContractContainer {
    padding: 10px;
  }

  .topRightMoonLogo {
    height: 200px;
    width: 200px;
  }

  .headerMargin {
    margin-bottom: 150px;
  }

  .topRightMoonLogo {
    display: none;
  }
}

@media (max-width: 1024px) {
  .createShitlordFlexLayout {
    width: 800px;
    max-width: 800px;
  }
}

@media (max-width: 950px) {
  .createShitlordFlexLayout {
    width: 420px;
    max-width: 420px;
  }
}

@media (max-width: 900px) {
  .FAQ {
    max-width: 480px;
    width: 480px;
  }
}

@media (max-width: 800px) {
  .BuyMax {
    top: -32px;
    left: 350px;
  }

  .claim-eth-token-logo {
    left: 388px;
    top: -18px;
  }
}

@media (max-width: 520px) {
  .ResponsiveFlexLayout {
    width: 360px;
    max-width: 360px;
  }

  .BuyMax {
    top: -32px;
    left: 220px;
  }

  .claim-eth-token-logo {
    left: 256px;
    top: -18px;
  }

  .LpTokenSelect {
    width: 137px;
  }

  .RevokeTokenSelect {
    width: 274px;
  }

  .FAQ {
    max-width: 360px;
    width: 360px;
  }
}

@media (max-width: 480px) {
  .BuyMax {
    top: -32px;
    left: 280px;
  }

  .claim-eth-token-logo {
    left: 320px;
    top: -18px;
  }

  .createShitlordFlexLayout {
    width: 360px;
    max-width: 360px;
  }
}

@media (max-width: 420px) {
  .ResponsiveFlexLayout {
    width: 300px;
    max-width: 300px;
  }

  .createShitlordFlexLayout {
    width: 280px;
    max-width: 280px;
  }

  .BuyMax {
    top: -32px;
    left: 230px;
  }

  .claim-eth-token-logo {
    left: 268px;
    top: -18px;
  }

  .LpTokenSelect {
    width: 112px;
  }

  .RevokeTokenSelect {
    width: 224px;
  }

  .FAQ {
    max-width: 300px;
    width: 300px;
  }

  .launches-switch {
    width: 100% !important;
  }
}

.LeftColumn {
  width: 50%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .LeftColumn {
    width: 100%;
    order: 0;
    max-width: 90%;
  }
}

.RightColumn {
  width: 70%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .RightColumn {
    width: 90%;
  }
}

.h2 {
  font-size: 18px;
  color: white;
  /* text-shadow: 1px 1px 4px black; */
  /* text-shadow: 0px 0px 1px black, 0px 0px 2px black, 0px 0px 4px black; */
}

.text {
  font-size: 18px;
}

.YouTube {
  width: 100%;
  margin-top: 50px;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 4px rgba(88, 255, 255, 0.1);
}

.navBar {
  height: 64px;
  /*background: #000428;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 30px;
  /*border-bottom: 1px solid #2a2b77;*/
  /*box-shadow: 0px 4px 8.2px 2px #4646aa;*/
}

.topBar {
  position: absolute;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #f8ffe8;
}

.countdownHeader {
  outline: none;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.25)),
    linear-gradient(90deg,
      rgb(174, 37, 174) -3.12%,
      rgb(238, 138, 247) 29.71%,
      rgb(210, 71, 203) 73.6%,
      rgb(215, 103, 237) 102.14%) !important;
  backdrop-filter: blur(38.5864px) !important;
  padding: 5px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 1px 1px 4px black; */
  display: block;
  font-weight: 600;
}

.countdownBigText {
  font-size: 20px;
  font-weight: 1000;
  margin-right: 3px;
}

.countdownSmallText {
  font-size: 12px;
  margin-right: 12px;
}

.navLink {
  color: white;
  padding: 20px;
  text-decoration: none;
}

.footer {
  padding-top: 1em;
  text-align: center;
  background: #53a553;
  align-items: center;
  margin: 0;
  padding-bottom: 24px;
  text-align: center;
}

.footerText {
  font-size: 16px;
  text-align: center !important;
  color: white;
  margin: auto;
}

body {
  background: #151515;
}

.countdownDescription {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
}

.countdown {
  background: #f3cc2f;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  margin: 4px 4px;
  border-radius: 5px;
  width: 27px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #f8ffe8;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.countdown-sub {
  font-size: 1rem;
  color: #aaa;
  text-align: center;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #f8ffe8;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* text-shadow: 0px 0px 1px black, 1px 1px 2px black, 2px 2px 4px black; */
}

li {
  margin-bottom: 16px;
  /* text-shadow: 0px 0px 1px black, 1px 1px 2px black, 2px 2px 4px black; */
}

li:last-child {
  margin-bottom: 0;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #f8ffe8;
  text-align: center;
  /* text-shadow: 0px 0px 1px black, 0px 0px 2px black, 0px 0px 4px black; */
}

h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #f8ffe8;
  text-align: center;
}

.gradientSeparator {
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
}

.gradientSeparator::before {
  content: '';
  position: absolute;
  top: -48px;
  left: 0;
  width: 100%;
  height: 48px;
  background: linear-gradient(to bottom,
      rgba(0, 4, 40, 0) 0%,
      rgba(0, 4, 40, 1) 100%);
  z-index: 1;
  /* Make sure it's on top of the GlobalContainer */
}

.textContainer {
  padding-left: 24px;
  padding-right: 24px;
}

a {
  color: white;
}

hr {
  opacity: 0.5;
}

.faqTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faqAnswer {
  margin-top: -16px;
  /* opacity: 0.5; */
}

.fas.fa-chevron-down {
  transition: transform 0.2s ease-in-out;
  color: #f8ffe8;
  margin-left: 10px;
}

.rotate {
  transform: rotate(90deg);
}

.chadfun {
  font-family: "Dela Gothic One", sans-serif;
  font-size: '36px';
}

.launchpad-card {
  /* max-width: 280px; */
  /* width: 280px; */
  min-width: 280px;
  /* height: 520px; */
  background: #0f2c22;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 25px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
}

.launchpad-status-icon {
  width: 5px;
  height: 5px;
  left: 8px;
  top: 5px;
  padding-right: 4px;
  padding-bottom: 8px;
}

.launchpad-status-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
}

.launchpad-status-sale-live {
  padding: 2px;
  width: 69px;
  height: 15px;
  background: #b6c7ff;
  border-radius: 4px;
  color: #291ce6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.launchpad-status-upcoming {
  padding: 2px;
  width: 69px;
  height: 15px;
  background: #ffd9b6;
  border-radius: 4px;
  color: #e6951c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.launchpad-status-closed {
  padding: 2px;
  width: 56px;
  height: 15px;
  background: #ffb6b6;
  border-radius: 4px;
  color: #f50a0a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.launchpad-badge-rug-proof {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  background: #f3cc2f;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 4px;
  width: 60px;
}

.launchpad-badge-all-in {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  background: #69d338;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 4px;
  width: 60px;
}

.launchpad-badge-doxed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  background: #a907f5;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 4px;
  width: 60px;
}

.launchpad-badge-kyc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  background: #ff9a02;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 4px;
  width: 60px;
}

@media screen and (max-width: 480px) {

  .launchpad-badge-rug-proof {
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
  }

  .launchpad-badge-all-in {
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
    width: 50px;
  }

  .launchpad-badge-doxed {
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
    width: 50px;
  }

  .launchpad-badge-kyc {
    padding-left: 5px;
    padding-right: 5px;
    margin: 4px;
    width: 50px;
  }
}

.lanchpad-eth-logo {
  width: 150px;
  height: 150px;
}

.launchpad-token-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #f8ffe8;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.launchpad-cap-type {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-left: -6px;
  color: #f8ffe8;
}

.lauchpad-cap-limit {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: red;
  text-align: center;
  margin-top: -10px;
}

.launchpad-addresses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.launchpad-address {
  display: flex;
  width: -webkit-fill-available;
}

.left-aligned {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

.CopyButton:hover {
  text-decoration: underline;
}

.center-aligned {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-aligned {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.launchpad-badge-text {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #f8ffe8;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.launchpad-progress-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #f8ffe8;
  margin-top: 10px;
  margin-bottom: 10px;
}

.launchpad-progress-bar {
  width: 100%;
  height: 12px;
  background: #397241;
  border: 1px solid #0d1c17;
  border-radius: 10px;
  font-size: 12px;
  color: #222;
}

.launchpad-progress-bar-filled {
  height: 10px;
  border-radius: 37px;
  background: linear-gradient(90deg, #76f951 0%, #f3cc2f 100%);
}

.launchpad-liquidity-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #291ce6;
  margin-top: 0px;
  margin-bottom: 2px;
}

.launchpad-progress-endpoints {
  display: flex;
  justify-content: space-between;
}

.launchpad-lockup-time-row {
  display: flex;
  justify-content: space-between;
}

.launchpad-progress-container {
  width: 100%;
}

.launchpad-info-container {
  display: flex;
  justify-content: space-between;
}

.commuity-progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.commuity-progress-container>a {
  text-decoration: blink;
}

.launchpad-lockup-time-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #f8ffe8;
  margin-top: 3px;
  margin-bottom: 3px;
}

.launchpad-sale-starts-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #f8ffe8;
}

.launchpad-sale-starts-timer {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f8ffe8;
}

.launchpad-details-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 32px;
  gap: 10px;
  width: 100%;
  height: 29px;
  left: 230px;
  top: 433px;
  background: #f3cc2f;
  border-radius: 5px;
  text-decoration: auto;
}

.launchpad-details-button-text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #f8ffe8;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.launchpad-sale-starts-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.launchpad-bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.launchpad-hr {
  padding-top: 8px;
  padding-bottom: 8px;
}

.create-token-button {
  padding: 12px 32px;
  background: #f3cc2f;
  border-radius: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-decoration: none;
  color: #f8ffe8;
}

.launches-switch {
  box-sizing: border-box;
  background: #f3cc2f;
  max-width: 1100px;
  margin: auto;
  border: 1px solid #f3cc2f;
  border-radius: 5px;
  padding: 16px 32px;
  width: calc(100% - 120px);
}

.launches-switch-all {
  box-sizing: border-box;
  background: #f3cc2f;
  max-width: 1255px;
  margin-left: 13px;
  border: 1px solid #f3cc2f;
  border-radius: 5px;
  padding: 16px 32px;
  width: calc(100% - 120px);
}

.launches-filter-bar-dropdown select {
  width: 120px;
  padding: 8px 16px;
  color: white;
  background-color: #f3cc2f;
  border: solid #f3cc2f 1px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 15px;
}

.launches-filter-bar-dropdown select:focus {
  outline: none;
}

.launches-filter-bar-dropdown option:checked {
  background-color: #f3cc2f;
}

.ConnectWalletButtonFirst {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  font-size: xx-large;
  margin-top: 70px;
}

.launches-switch-active {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 3px solid;
  padding-bottom: 7px;
  border-color: #f3cc2f;
  border-radius: 1px;
  line-height: 24px;
  color: #f8ffe8;
  text-decoration: none;
  margin: 0px 16px;
}

.launches-switch-passive {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #888888;
  text-decoration: none;
  margin: 0px 16px;
}

.launches-switch-passive:hover {
  font-style: normal;
  font-weight: 600;
  /* border-bottom: 3px solid;*/
  padding-bottom: 7px;
  border-color: #f3cc2f;
  border-radius: 1px;
  line-height: 24px;
  color: #f3cc2f;
  text-decoration: none;
  margin: 0px 16px;
}

@media screen and (max-width: 480px) {
  .launches-switch-passive {
    font-size: 13px;
    margin: 0px 4px;
  }

  .launches-switch-active {
    font-size: 13px;
    margin: 0px 4px;
    padding-bottom: 11px;
  }

  .create-token-button {
    padding: 8px 16px;
    background: #f3cc2f;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    color: #f8ffe8;
  }
}

.launchpad-card-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  max-width: 1280px;
  margin: auto;
  justify-items: stretch;
}

.launchpad-eth-logo-container {
  display: flex;
  justify-content: left;
  background: white;
  width: 100%;
  margin: auto auto 120px;
  border-radius: 50%;
}

.claim-eth-logo-container {
  display: flex;
  justify-content: center;
  background: white;
  width: min-content;
  margin: auto;
  border-radius: 50%;
  margin-top: 170px;
}

@media (max-width: 480px) {
  .launchpad-card-grid {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

@media (max-width: 340px) {
  .launchpad-card {
    margin-left: -6px;
  }
}

.fairlaunch-token-info {
  text-align: left;
  width: 90%;
  /* margin-left: 32px; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f3cc2f;
}

.fairlaunch-allocation-buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.fairlaunch-allocation-button {
  background: #f3cc2f;
  border-radius: 18px;
  border: solid 0px transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 4px 8px;
  color: #222;
  cursor: pointer;
}

.fairlaunch-date-time {
  position: relative;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 16px;
}

.calendar-icon {
  position: absolute;
  top: 7px;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

@media (max-width: 400px) {
  .calendar-icon {
    display: none;
  }
}

.fairlaunch-date-time-wrapper {
  position: relative;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
  padding-right: 34px;
}

.fairlaunch-date-time-wrapper,
.fairlaunch-date-time-wrapper>div.react-datepicker-wrapper,
.fairlaunch-date-time-wrapper>div>div.react-datepicker__input-container .fairlaunch-date-time-wrapper>div>div.react-datepicker__input-container input {
  width: 100%;
}

.claim-card {
  /* max-width: 800px; */
  min-width: 280px;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 25px;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: auto;
  background-color: #27462b;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  /* border: solid #f3cc2f 1px; */
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .claim-card {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.claim-eth-logo {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.claim-card-logo {
  /* border: 2px solid black; */
  border-radius: 50%;
  width: 54px;
  height: 54px;
  position: absolute;
  top: -27px;
  left: 20px;
}

.launchpad-blockchain-logo {
  filter: drop-shadow(0px 0px 2px #000);
  border-radius: 50%;
  position: absolute;
  top: 8px!important;
  right: 8px!important;
  width: 32px!important;
  height: 32px!important;
  z-index: 10;
}

.social-section {
  position: absolute;
  top: -10px;
  right: 12px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.claim-token-name {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  color: #f8ffe8;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.claim-cap-type {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #f8ffe8;
}

.claim-cap-limit {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3e54a0;
  text-align: center;
  margin-top: -10px;
}

.claim-progress-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #f8ffe8;
  margin-top: 3px;
  margin-bottom: 3px;
}

.claim-progress-bar {
  width: 100%;
  height: 13px;
  background: #dcd7d7;
  border-radius: 4px;
}

.claim-progress-bar-filled {
  height: 13px;
  background: #f3cc2f;
  border-radius: 4px;
}

.claim-liquidity-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #291ce6;
  margin-top: 0px;
  margin-bottom: 2px;
}

.claim-progress-endpoints {
  display: flex;
  justify-content: space-between;
}

.claim-lockup-time-row {
  display: flex;
  justify-content: space-between;
}

.claim-progress-container {
  width: 100%;
}

.claim-lockup-time-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #f8ffe8;
  margin-top: 3px;
  margin-bottom: 3px;
}

.claim-sale-starts-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #f8ffe8;
}

.claim-sale-starts-timer {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #f8ffe8;
}

.claim-details-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 32px;
  gap: 10px;
  width: 90px;
  height: 29px;
  left: 230px;
  top: 433px;
  background: #f3cc2f;
  border-radius: 5px;
}

.claim-details-button-text {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #f8ffe8;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.claim-sale-starts-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.claim-bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.claim-hr {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}

.claim-header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f8ffe8;
}

.claim-text,
.fair-launch-info-label,
.fair-launch-info-value,
.token-info-label,
.token-info-value {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #f8ffe8;
}

.token-info-item,
.fair-launch-info-item {
  display: flex;
  justify-content: space-between;
}

.chat-eth-logo-container {
  display: flex;
  align-items: center;
}

.chat-profile-avatar {
  position: inherit;
  width: 40px;
  height: 40px;
}

.chatContent>p {
  margin: 0px;
}

@media screen and (max-width: 480px) {

  .claim-text,
  .fair-launch-info-label,
  .fair-launch-info-value,
  .token-info-label,
  .token-info-value {
    font-size: 12px;
    line-height: 14px;
  }

  .claim-card {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -20px;
    margin-right: -20px;
  }
}

.token-info-address {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 5px;
  color: #f3cc2f;
  word-break: break-all;
  max-width: 100%;
  cursor: pointer;
}

.token-info-address-warning {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #fd2525;
}

.token-info-subitem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}

.avoid-scam {
  margin-top: -50px;
  box-sizing: border-box;
  background: #f3cc2f;
  border: 1px solid #f8ffe8;
  width: 100%;
}

.avoid-scam-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #f8ffe8;
  width: 100%;
}

.ClaimLeftColumn {
  width: 45%;
  padding: 1rem 0;
}

@media (max-width: 1280px) {
  .ClaimLeftColumn {
    width: 100%;
    order: 0;
  }

  .claim-eth-logo-container {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }
}

.ClaimRightColumn {
  width: 75%;
  padding: 1rem;
  margin: 0 16px;
  margin-bottom: 32px;
}

@media (max-width: 1280px) {
  .ClaimRightColumn {
    width: 90%;
  }
}

.claim-button {
  margin-top: 8px;
  background: #f3cc2f;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #f8ffe8;
  padding: 8px 8px;
  width: -webkit-fill-available;
  cursor: pointer;
  border: 1px solid transparent;
}

.claim-button:disabled {
  background: #8f4044;
}

.claim-button:hover {
  background: #c22a32;
}

.listSelected {
  color: white;
  background-color: #f3cc2f;
  padding: 5px;
  border-radius: 5px;
}

.top-bar {
  position: absolute;
  top: 100px;
  width: 100%;
  height: 47px;
  background-color: #2f5734b0;
  display: flex;
  align-items: center;
}

.top-bar .rocket {
  width: 0px;
  height: 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  flex: none;
  flex-grow: 0;
  margin-left: 60px;
}

.top-bar-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #f8ffe8;
  text-decoration: auto;
}

.top-bar-text2 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #b6c7ff;
  flex: none;
  flex-grow: 0;
  margin-right: 3%;
}

.moon {
  position: absolute;
  width: 180px;
  height: 180px;
  right: 72px;
  top: 143px;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.75));
}

.left-bar {
  position: fixed;
  left: 0;
  top: 60px;
  width: 120px;
  min-height: calc(100vh - 60px);
  background: #f3cc2f;
  height: 100%;
  backdrop-filter: blur(32px);
  z-index: 3;
  overflow: hidden;
  transition: width 0.3s ease, opacity 0.3s ease-in-out;
  border-right: 1px solid #f3cc2f;
}

.left-bar-menu {
  position: absolute;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 48px !important;
  height: 48px !important;
  flex: none;
  flex-grow: 0;
  margin-left: 6px;
  z-index: 4;
  display: none;
  cursor: pointer;
}

.left-bar-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: 0;
  margin-top: 0px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.left-bar-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.left-bar-text {
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  transition: opacity 0.3s ease;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.left-bar-text-active {
  opacity: 1;
  display: block;
}

.left-bar-hr {
  color: white;
  width: 90%;
}

.left-bar {
  width: 120px;
}

.left-bar:hover .left-bar-text {
  visibility: visible;
  opacity: 1;
}

.left-bar-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 8px;
  margin: 4px;
}

@media screen and (max-width: 1024px) {
  .GlobalContainer {
    padding-left: 0px;
  }

  .tokenHeader {
    margin-left: 0px;
  }

  .left-bar {
    width: 0px;
  }

  .left-bar-menu {
    display: block;
  }

  .top-bar {
    width: calc(100%);
  }
}

.left-bar-expanded {
  visibility: visible;
  opacity: 1;
  width: 120px;
}

.left-bar:not(.left-bar-expanded):hover .left-bar-text {
  visibility: visible;
  opacity: 1;
}

.fairlaunch-date-time {
  border: solid #f3cc2f 1px !important;
}

.upload-box {
  width: 100%;
  min-width: 175px;
  max-width: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #203823;
  border: solid #f3cc2f 1px;
  border-radius: 25px;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
}

.upload-box-banner {
  width: 100%;
  min-width: 175px;
  max-width: 350px;
  height: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #203823;
  border: solid #f3cc2f 1px;
  border-radius: 25px;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 0;
}

.upload-box:hover {
  border: 1px solid #aaa;
}

.upload-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
  margin-bottom: 4px;
  filter: invert(100%);
}

.upload-text {
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #f8ffe8;
}

.profile-upload-text {
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #f8ffe8;
  padding: 10px;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
  object-fit: contain;
}

.InputSectionLogo {
  width: 30%;
}

.InputSectionBanner {
  width: 60%;
}

@media screen and (max-width: 1280px) {
  .InputSectionLogo {
    width: 30%;
  }

  .InputSectionBanner {
    width: 50%;
  }

  .launches-switch-active {
    border-bottom: 0px;
  }
}

@media screen and (max-width: 800px) {
  .InputSectionLogo {
    width: 100%;
  }

  .InputSectionBanner {
    width: 100%;
  }
}

.upload-button {
  padding: 8px 16px;
  background-color: #0099;
  color: white;
  border: none;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #005f;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin: 0 5px;
}

.loadMoreText {
  text-align: center;
  margin-top: 100px;
  font-size: larger;
  margin-bottom: 0px;
  cursor: pointer;
}

.edit-profile-button {
  box-sizing: border-box;
  background: #151515;
  border: solid #f3cc2f 1px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #f8ffe8;
  text-decoration: auto;
}

.save-button {
  display: flex;
  align-items: center;
  margin: auto;
  cursor: pointer;
  background: #f3cc2f;
  border: 1px solid black;
  color: #222e;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 18px;
  box-shadow: rgb(0, 0, 0) 1px 1px 0px 0px;
}

.profile-wallet-address {
  box-sizing: border-box;
  background: #151515;
  border: solid #f3cc2f 1px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #f8ffe8;
  padding: 10px;
  width: 90%;
}

.profile-text-input {
  box-sizing: border-box;
  background: transparent;
  border: solid #f3cc2f 1px;
  border-radius: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f8ffe8;
  padding: 12px;
  /* width: 470px; */
  max-width: 100%;
}

.profile-logo-input,
.profile-banner-input {
  box-sizing: border-box;
  background: #27462b;
  border: solid #f3cc2f 1px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #f8ffe8;
  padding: 32px;
  width: 470px;
  max-width: 100%;
  padding-top: 5px;
  margin-right: 8px;
}

@media (max-width: 550px) {
  .profile-text-input {
    width: 320px;
  }
}

@media (max-width: 360px) {
  .profile-text-input {
    width: 280px;
  }
}

.profile-user-details-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10px;
  margin-left: 50px;
  margin-top: 25px;
  align-items: center;
}

@media (max-width: 768px) {
  .profile-user-details-wrapper {
    grid-template-columns: 1fr;
  }
}

.profile-text {
  margin-top: 17px;
}

.user-details-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
}

.profile-user-avatar-wrapper {
  position: absolute;
  top: 0px;
  left: -120px;
}

@media screen and (max-width: 640px) {
  .profile-user-avatar-wrapper {
    position: relative;
    top: 0px;
    left: 0px;
    margin-top: 25px;
  }
}

.balances-box {
  max-width: 800px;
  min-width: 280px;
  background: #27462b;
  border-radius: 10px;
  flex: none;
  border: solid #f3cc2f 1px;
  order: 2;
  flex-grow: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
  margin-top: 20px;
  display: flex;
}

.balances-box>a {
  width: 100%;
}


.trades-box {
  max-width: 800px;
  min-width: 280px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
  display: flex;
  background-color: #1a2d1d;
  border-radius: 25px;
  margin-bottom: 2px;
}

.trades-box>a {
  width: 100%;
}

.Balance-token-name {
  color: rgb(255, 255, 255);
}

.trade-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.balanceContent>p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.profile-user-avatar {
  width: 100%;
  text-align: center;
  border-radius: 9999px;
  overflow: hidden;
}

.profile-user-avatar-image {
  max-width: 96px;
  max-height: 96px;
}

.profile-user-bio {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #f8ffe8;
}

.profile-user-followers {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #f8ffe8;
}

.profile-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #f8ffe8;
}

.profile-user-name {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #f8ffe8;
}

.tokenHeader1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.top-trending {
  display: flex;
  align-items: center;
}

.buys {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  background-color: #b04851;
  padding: 14px;
}

.right-triangle {
  clip-path: polygon(0 0, 75% 0, 100% 50%, 75% 100%, 0 100%, 0 0);
}

.logo-header {
  display: flex;
  flex-direction: row;
}

.top-bar-item-box {
  display: flex;
}

.RefBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.top-bar-address {
  margin-left: 5px;
  color: #aaaaaa;
  text-decoration: none;
  display: flex;
  font-size: 13px;
  align-items: center;
}

.top-bar-description {
  margin-left: 5px;
  color: white;
  font-size: 13px;
}

.top-bar-token {
  /* margin-top: 5px;
  margin-left: 5px; */
  color: #f3cc2f;
  font-weight: bold;
}

.top-bar-token-icon {
  margin-left: 5px;
  margin-right: 10px;
}

.logo-text {
  margin-top: 10px;
  margin-left: 10px;
}

.hide-icon {
  display: none;
}

.custom-radio-button-wrapper {
  box-sizing: border-box;
  width: fit-content;
  object-fit: contain;
  padding: 6px 12px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 20px;
}

.custom-radio-button-wrapper2 {
  box-sizing: border-box;
  width: fit-content;
  object-fit: contain;
  border-radius: 5px;
  padding: 6px 12px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.custom-radio-button-unselected {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent !important;
  border: 1px solid #f3cc2f;
  color: white;
  padding: 5px 10px;
  border-radius: 25px;
}

.custom-radio-button-selected {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #f3cc2f;
  border: 1px solid transparent;
  color: #222;
  padding: 5px 10px;
  border-radius: 25px;
}

.right-panel {
  position: absolute;
  top: 60px;
  right: 0;
  flex: 1;
  min-height: calc(100% - 129px);
  margin-left: 15px;
  border: 1px solid #f3cc2f;
  padding: 10px;
  height: 100%;
  background-color: #f3cc2f;
  min-width: 320px;
}

.right-panel-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .right-panel {
    display: none;
  }

  .launches-all-padding {
    padding-right: 0px;
  }
}

.trophy {
  top: -32px;
  left: -5px;
  filter: drop-shadow(4px 4px 8px #000);
}

.profile-card {
  max-width: 800px;
  min-width: 280px;
  /* height: 520px; */
  background: #27462b;
  /* box-shadow: 0px 0px 17.5px 10px rgba(232, 232, 234, 0.37); */
  border-radius: 10px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 120px;
  margin: auto;
}

.profile-avatar {
  filter: drop-shadow(0px 0px 2px #000);
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 120px;
  width: 80px;
  height: 80px;
}

.profile-name {
  display: flex;
  flex-direction: row;
  margin-left: 100px;
  padding-top: 50px;
  margin-top: -60px;
  font-size: 1.25rem;
}

.profile-edit {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
}

.profile-item {
  display: flex;
  flex-direction: row;
}

.profile-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #f8ffe8;
  margin-left: 8px;
  margin-bottom: -10px;
  width: 100%;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-file-input-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: solid #f3cc2f 1px;
  background: #27462b;
  padding: 0px;
  border-radius: 25px;
  width: 100%;
}

.custom-file-input {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 5px;
}

.custom-file-input input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.profile-logo-input-button,
.profile-banner-input-button {
  background: #f3cc2f;
  border: 1px solid transparent;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  width: 135px;
}

.hidden {
  display: none;
}

.profile-logo-input-text,
.profile-banner-input-text {
  cursor: pointer;
  background: none;
  border: 1px solid transparent;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 18px;
  flex-grow: 1;
  outline: none;
}

@media screen and (max-width: 1024px) {
  .custom-radio-button-wrapper {
    gap: 6px;
  }

  .profile-card {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .buys {
    margin-left: 60px;
  }

  .left-bar {
    margin-left: -1px;
  }

  .footer {
    padding-left: 20px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.fair-launch-box {
  border: 1px solid #f3cc2f;
  background-color: #f3cc2f;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  color: white;
}

.fair-launch-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.fair-launch-label {
  color: lightgray;
}

@media screen and (max-width: 640px) {
  .fair-launch-container {
    flex-direction: column;
  }

  .fair-launch-box {
    margin: auto;
    margin-bottom: 10px;
    min-width: 200px;
    display: flex;
    justify-content: space-between;
  }
}

.animation-increasing {
  fill: #75f951df;
  animation: increasing 1.4s linear 0ms infinite;
}

@keyframes increasing {
  0% {
    transform: translateY(3px);
    fill: #75f95128;
  }
  100% {
    transform: translateY(-3px);
    fill: #75f951f0;
  }
}
.carousel-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  border-color: #4b5563;
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto; /* Flex grow/shrink, base size is auto */
  min-width: 150px; /* Minimum width of each card */
  max-width: 350px; /* Maximum width of each card */
  height: 100%;
  background: #0f2c23;
  &:hover {
    transform: scale(0.95);
    box-shadow: 1px 1px 1px rgba(86, 236, 144, 0.362);
  }
}

.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  height: 100%;
  gap: 1px;
  width: max-content; /* Track width is based on content */
  animation: slide 20s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 100vw)); /* Slide based on screen size */
  }
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .carousel-card {
    min-width: 100px;
  }
}

@media (max-width: 480px) {
  .carousel-card {
    min-width: 80px;
  }
}

.slider {
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  background: #219430;
  cursor: pointer;
  border-radius: 50%;
}